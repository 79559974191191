<template>
  <HeadlessPopover v-if="startDate" @click.stop>
    <HeadlessPopoverButton
      class="text-left text-sm disabled:cursor-not-allowed"
      :class="stage !== 'FINISHED' ? UNDERLINE_HOVER_STYLES : ''"
      :disabled="stage === 'FINISHED'"
      @click="
        (e) => {
          if (stage === 'FINISHED') {
            e.preventDefault()
          }
        }
      "
    >
      <slot />
    </HeadlessPopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <HeadlessPopoverPanel
        v-slot="{ close }"
        class="absolute z-50 w-48 bg-white shadow-md"
      >
        <header>
          <h3 class="mt-1 p-2">{{ $t('auction-calendar-popover.title') }}</h3>
          <button
            class="sm absolute right-2 top-2 flex text-black duration-300 hover:scale-110 hover:text-primary"
            @click="close"
          >
            <span class="sr-only">{{ $t('close') }} </span>
            <Icon name="ic:outline-close" />
          </button>
        </header>
        <div class="relative grid text-sm text-black" @click="close">
          <button
            class="flex items-center gap-2 p-2 hover:bg-slate-200"
            @click="onCalendarPick('ics')"
          >
            <Icon name="ic:baseline-apple" />
            Apple
          </button>
          <button
            class="flex items-center gap-2 p-2 hover:bg-slate-200"
            @click="onCalendarPick('google')"
          >
            <Icon name="logos:google-calendar" />
            Google Calendar
          </button>
          <button
            class="flex items-center gap-2 p-2 hover:bg-slate-200"
            @click="onCalendarPick('outlook')"
          >
            <Icon name="vscode-icons:file-type-outlook" />
            Outlook
          </button>
        </div>
      </HeadlessPopoverPanel>
    </transition>
  </HeadlessPopover>
</template>

<script lang="ts" setup>
import type { DisplayingAuction } from '@autobid/ui/types/components/AuctionsData'
import { useAuctionLink } from '@autobid/ui/composables/useAuctionLink'
import { isOtherDomain } from '@autobid/ui/utils/isOtherDomain'
import { UNDERLINE_HOVER_STYLES } from '@autobid/ui/constants/UNDERLINE_HOVER_STYLES'

const { type, startDate, title, descriptions, department, stage, appId, slug } =
  defineProps<DisplayingAuction>()
const auctionLink = useAuctionLink({ appId, slug, stage: 'ONLINE', startDate })
const { SELF_URL } = useRuntimeConfig().public

const onCalendarPick = async (
  calendar: 'google' | 'outlook' | 'office365' | 'yahoo' | 'ics'
) => {
  const absoluteAuctionLink = isOtherDomain(auctionLink.value ?? '')
    ? auctionLink.value
    : `${SELF_URL}${auctionLink.value}`
  const event = {
    title,
    description: [absoluteAuctionLink, descriptions].join('\n'),
    start: startDate,
    location: `${type} - ${department.name}`,
    duration: [0, 'hour']
  } as const

  const module = await import('calendar-link')
  const pickedModule = module[calendar]
  const link = pickedModule(event as any)
  open(link)
}
</script>
